// routes/Routes.tsx
import React, { Suspense, lazy } from "react";
import { Navigate, Outlet } from "react-router-dom";
import * as Sentry from "@sentry/react";
import ErrorFallback from "../components/ErrorFallback";

// Lazy load components
const LogIn = lazy(() => import("../pages/auth/log-in"));
const SignUp = lazy(() => import("../pages/auth/sign-up"));
const Dashboard = lazy(() => import("../pages/app/dashboard"));
const ForgotPassword = lazy(() => import("../pages/auth/forgot-password"));
const ForgotPasswordOtp = lazy(
  () => import("../pages/auth/forgot-password-otp")
);
const ChangePassword = lazy(() => import("../pages/auth/change-password"));
const AuthLayout = lazy(() => import("../pages/auth/auth-layout"));
const AuthLayoutAlt = lazy(() => import("../pages/auth/auth-layout-alt"));
const AppLayout = lazy(() => import("../pages/app/app-layout"));
const ProfileSettings = lazy(() => import("../pages/app/profile-settings"));
const NotFoundPage = lazy(() => import("../pages/NotFound"));
const Portfolios = lazy(() => import("../pages/app/portfolios"));
const Manual = lazy(() => import("../pages/app/trades/manually"));
const TradesLog = lazy(() => import("../pages/app/trades/tradeslog"));
const TradeDetails = lazy(() => import("../pages/app/trades/tradedetails"));
const TradesImports = lazy(() => import("../pages/app/trades/TradesImport"));
const ImportHistory = lazy(() => import("../pages/app/trades/importhistory"));
const Reports = lazy(() => import("../pages/app/reports"));
const Pricing = lazy(() => import("../pages/app/pricing"));
const Subscription = lazy(() => import("../pages/app/subscription"));

const mainRouter = (isAuthenticated: boolean) => [
  {
    path: "*",
    element: (
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorFallback
            error={error}
            resetError={resetError}
            className="min-h-screen"
          />
        )}
      >
        <Suspense fallback={<></>}>
          {isAuthenticated ? (
            <AppLayout>
              <NotFoundPage />
            </AppLayout>
          ) : (
            <NotFoundPage />
          )}
        </Suspense>
      </Sentry.ErrorBoundary>
    ),
  },
  {
    path: "/",
    element: (
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorFallback error={error} resetError={resetError} />
        )}
      >
        <Suspense fallback={<></>}>
          {isAuthenticated ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <AuthLayout />
          )}
        </Suspense>
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        path: "",
        element: <Navigate to={"/login"} />,
      },
      {
        path: "login",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <LogIn />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
      {
        path: "signup",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <SignUp />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: "/",
    element: (
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorFallback error={error} resetError={resetError} />
        )}
      >
        <Suspense fallback={<></>}>
          <AuthLayoutAlt />
        </Suspense>
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        path: "forgot-password",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <ForgotPassword />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
      {
        path: "forgot-password-otp",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <ForgotPasswordOtp />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
      {
        path: "change-password",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <ChangePassword />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
    ],
  },
  {
    path: "/",
    element: (
      <Sentry.ErrorBoundary
        fallback={({ error, resetError }) => (
          <ErrorFallback error={error} resetError={resetError} />
        )}
      >
        <Suspense fallback={<></>}>
          {!isAuthenticated ? <Navigate to="/login" replace /> : <AppLayout />}
        </Suspense>
      </Sentry.ErrorBoundary>
    ),
    children: [
      {
        path: "dashboard",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <Dashboard />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
      {
        path: "profile-settings",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <ProfileSettings />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
      {
        path: "portfolios",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <Portfolios />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
      {
        path: "trades",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: (
              <Sentry.ErrorBoundary
                fallback={({ error, resetError }) => (
                  <ErrorFallback error={error} resetError={resetError} />
                )}
              >
                <Suspense fallback={<></>}>
                  <TradesLog />
                </Suspense>
              </Sentry.ErrorBoundary>
            ),
          },
          {
            path: ":symbol", // Matches "/trades/:symbol"
            element: (
              <Sentry.ErrorBoundary
                fallback={({ error, resetError }) => (
                  <ErrorFallback error={error} resetError={resetError} />
                )}
              >
                <Suspense fallback={<></>}>
                  <TradesLog />
                </Suspense>
              </Sentry.ErrorBoundary>
            ),
          },
          {
            path: "import",
            element: (
              <Sentry.ErrorBoundary
                fallback={({ error, resetError }) => (
                  <ErrorFallback error={error} resetError={resetError} />
                )}
              >
                <Suspense fallback={<></>}>
                  <TradesImports />
                </Suspense>
              </Sentry.ErrorBoundary>
            ),
          },
          {
            path: "importhistory",
            element: (
              <Sentry.ErrorBoundary
                fallback={({ error, resetError }) => (
                  <ErrorFallback error={error} resetError={resetError} />
                )}
              >
                <Suspense fallback={<></>}>
                  <ImportHistory />
                </Suspense>
              </Sentry.ErrorBoundary>
            ),
          },
          {
            path: "manual",
            element: (
              <Sentry.ErrorBoundary
                fallback={({ error, resetError }) => (
                  <ErrorFallback error={error} resetError={resetError} />
                )}
              >
                <Suspense fallback={<></>}>
                  <Manual />
                </Suspense>
              </Sentry.ErrorBoundary>
            ),
          },
          {
            path: "details/:tradeID",
            element: (
              <Sentry.ErrorBoundary
                fallback={({ error, resetError }) => (
                  <ErrorFallback error={error} resetError={resetError} />
                )}
              >
                <Suspense fallback={<></>}>
                  <TradeDetails />
                </Suspense>
              </Sentry.ErrorBoundary>
            ),
          },
        ],
      },
      {
        path: "reports",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <Reports />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
      {
        path: "pricing",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <Pricing />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
      {
        path: "subscription",
        element: (
          <Sentry.ErrorBoundary
            fallback={({ error, resetError }) => (
              <ErrorFallback error={error} resetError={resetError} />
            )}
          >
            <Suspense fallback={<></>}>
              <Subscription />
            </Suspense>
          </Sentry.ErrorBoundary>
        ),
      },
    ],
  },
];

export default mainRouter;
