import { Route, Routes, useLocation, useRoutes } from "react-router-dom";
import mainRouter from "./routes/Routes";
import ToasterMain from "./components/ToasterMain";
import { useAppContext } from "./context/AppContext";
import UserVerified from "./pages/auth/user-verified";
import * as Sentry from "@sentry/react";
import ErrorFallback from "./components/ErrorFallback";
import { setupErrorMonitoring } from "./errors/errorMonitoring";

// setting up sentry
setupErrorMonitoring();

const useSentryRoutes = Sentry.wrapUseRoutes(useRoutes);

function App() {
  const { userLogin } = useAppContext();
  const mainRoutes = useSentryRoutes(mainRouter(userLogin ? true : false));

  const location = useLocation();

  const isAdditionalRoute = location.pathname.startsWith("/auth/verify-link/");

  return (
    <>
      <ToasterMain />
      {!isAdditionalRoute ? (
        <div className="relative min-h-screen font-inter">{mainRoutes}</div>
      ) : (
        <Sentry.ErrorBoundary
          fallback={({ error, resetError }) => (
            <ErrorFallback error={error} resetError={resetError} />
          )}
        >
          <Routes>
            <Route
              path="/auth/verify-link/:encodedLink"
              element={<UserVerified />}
            />
          </Routes>
        </Sentry.ErrorBoundary>
      )}
    </>
  );
}

export default App;
