/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangePasswordDTO } from '../models/ChangePasswordDTO';
import type { ForgetPasswordDTO } from '../models/ForgetPasswordDTO';
import type { LoginDTO } from '../models/LoginDTO';
import type { ResendOtpDTO } from '../models/ResendOtpDTO';
import type { ResetPasswordDTO } from '../models/ResetPasswordDTO';
import type { SignupDTO } from '../models/SignupDTO';
import type { VerifyOTPDTO } from '../models/VerifyOTPDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthenticationService {
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authControllerLogin(
        requestBody: LoginDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/auth/login',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authControllerSignup(
        requestBody: SignupDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/auth/signup',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authControllerResendSignUpVerificationMail(
        requestBody: ResendOtpDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/auth/signup-verif',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param encodedString
     * @returns any
     * @throws ApiError
     */
    public static authControllerUserVerified(
        encodedString: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/auth/user-verified/{encodedString}',
            path: {
                'encodedString': encodedString,
            },
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public static authControllerLogoutSession(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/auth/logout',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authControllerPasswordUpdate(
        requestBody: ChangePasswordDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/auth/update-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param type
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authControllerForgetPassword(
        type: string,
        requestBody: ForgetPasswordDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/auth/forget-password',
            query: {
                'type': type,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @deprecated
     * @param encodedString
     * @returns any
     * @throws ApiError
     */
    public static authControllerVerifyLink(
        encodedString: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/api/auth/verify-link/{encodedString}',
            path: {
                'encodedString': encodedString,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authControllerResetPassword(
        requestBody: ResetPasswordDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/auth/reset-password',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param type
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authControllerResendOtp(
        type: string,
        requestBody: ResendOtpDTO,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/auth/resend-otp',
            query: {
                'type': type,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public static authControllerVerifyPasswordResetOtp(
        requestBody: VerifyOTPDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/api/auth/verify-otp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
